<template>
  <v-dialog
    v-model="dialog"
    transition="scale-transition"
    persistent
    max-width="750px"
  >
    <v-card outlined>
      <v-card-title class="title">
        {{ $t("absent.absent_per_subjects") }}
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-2">
        <v-row>
          <v-col cols="4">
            {{ $t("app.subjects") }}
          </v-col>
          <v-col cols="8"> : {{ subjectsName }} </v-col>
          <v-col cols="4">
            {{ $t("absent.clock") }}
          </v-col>
          <v-col cols="8"> : {{ time }} </v-col>
          <v-col cols="4">
            {{ $t("app.class") }}
          </v-col>
          <v-col cols="8"> : {{ className }} </v-col>
          <v-col cols="12" class="mt-5">
            <v-data-table
              :headers="tableHeaders"
              :items="dataTable"
              :items-per-page="dataTable.length"
              disable-pagination
              hide-default-footer
              class="elevation-0"
            >
              <template #item.date="{ item }">
                {{ getDate(item.date) }}
              </template>
              <template #item.status_text="{ item }">
                <div v-if="!item.approved && item.status === '1'">
                  {{ item.status_text }}
                  <span class="grey--text">
                    ({{ $t("absent.absent_has_not_been_approved") }})
                  </span>
                </div>
                <div v-else>{{ item.status_text }}</div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-layout justify-end>
          <v-btn outlined small dark color="primary" @click="closeDialog">
            {{ $t("app.back") }}
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { lessonAbsentStatus } from "@/api/admin/absent/absent";
import moment from "moment/moment";
import { mapGetters } from "vuex";

export default {
  props: {
    dialog: Boolean,
    subjectsName: String,
    time: String,
    className: String,
    dataTable: Array
  },
  created() {
    moment.locale(this.g_language);
  },
  mounted() {
    this.getlessonAbsentStatus();
  },
  computed: mapGetters(["g_language"]),
  data() {
    return {
      loadingStatusItems: false,
      modelStatus: null,
      statusItems: [],
      infoAddAbsent: "",
      tableHeaders: [
        {
          text: this.$i18n.t("recap_absent.date"),
          align: "left",
          value: "date",
          width: 130
        },
        {
          text: this.$i18n.t("bk.table.status"),
          value: "status_text",
          width: 150
        },
        {
          text: this.$i18n.t("journal.table.information"),
          sortable: false,
          value: "note",
          width: 180
        }
      ]
    };
  },
  methods: {
    getDate: date => moment(date).format("DD MMMM YYYY"),
    async getlessonAbsentStatus() {
      try {
        this.loadingStatusItems = true;
        const response = await lessonAbsentStatus();
        if (response.data.code) {
          this.statusItems = response.data.data.filter(item => item.id !== "0");
        }
      } catch (error) {
        console.error("getlessonAbsentStatus()\n", error);
      } finally {
        this.loadingStatusItems = false;
      }
    },
    closeDialog() {
      this.$emit("close");
    }
  }
};
</script>
